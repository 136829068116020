import logo from './logo.svg';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ImageStack from './components/imageStack';
import '../src/components/ImageStack.css';


function App() {

  const carouselImages = [
    '/assets/carousel-1.jpg',
    '/assets/carousel-2.jpg',
    '/assets/carousel-3.jpg',
    '/assets/carousel-4.jpg'
  ];

  const imageUrls = [
    process.env.PUBLIC_URL + '/assets/vplus_1.jpg',
    process.env.PUBLIC_URL + '/assets/vplus_2.jpg',
    process.env.PUBLIC_URL + '/assets/vplus_3.jpg',
    process.env.PUBLIC_URL + '/assets/vplus_4.jpg',
    process.env.PUBLIC_URL + '/assets/vplus_5.jpg',
    process.env.PUBLIC_URL + '/assets/vplus_6.jpg',
    process.env.PUBLIC_URL + '/assets/vplus_7.jpg',
    process.env.PUBLIC_URL + '/assets/vplus_8.jpg',
    process.env.PUBLIC_URL + '/assets/vplus_9.jpg',

  ];
  const vnpayUrls = [
    process.env.PUBLIC_URL + '/assets/VNPAY_1.jpg',
    process.env.PUBLIC_URL + '/assets/VNPAY_2.jpg',
    process.env.PUBLIC_URL + '/assets/VNPAY_3.jpg',
    process.env.PUBLIC_URL + '/assets/VNPAY_4.jpg'

  ];
  return (
    <div className="App">
         {/* <Carousel showThumbs={false} autoPlay infiniteLoop>
        {carouselImages.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`carousel-${index + 1}`} />
          </div>
        ))}
      </Carousel> */}
      {/* <InvestmentIntro /> */}
      {/* <ProjectInfo />
      <ProjectBenefits />
      <UsageProcess /> */}
      {/* <ImageStack images={imageUrls} />      */}

      <Routes>
        <Route path="/" element={<ImageStack images={imageUrls} />} />
        <Route path="/vnpay" element={<ImageStack images={vnpayUrls} />} />
      </Routes>




    </div>
  );
}

export default App;
